import { LocalStorageService } from './@resources/services/local-storage.service';
import { Global } from './@core/global/global';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'amanda';
  constructor(
    public translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private storageService: LocalStorageService,
    private toastrService: ToastrService,
    public global: Global,
    private meta: Meta,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.changeDirection();
    translateService.addLangs(['ar', 'en']);
    this.switchLang(
      this.storageService.get('lang') ? this.storageService.get('lang') : 'ar'
    );
  }

  switchLang(lang: string) {
    this.translateService.use(lang);
    this.global.lang = lang;
  }

  changeDirection() {
    if (this.storageService.get('lang') == 'en') {
      this.document.documentElement.dir = 'ltr';
    } else {
      this.document.documentElement.dir = 'rtl';
    }
  }

  ngOnInit() {
    this.meta.updateTag({
      property: 'og:title',
      content: 'Amanda',
    });
    this.route.queryParams.subscribe((params) => {
      if (params?.state === 'FAILED') {
        this.toastrService.error(params.response_message);
      }
    });

    this.route.queryParams.subscribe((params: any) => {
      if (params.redirect) {
        this.router.navigateByUrl(params['redirect']);
      }
    });
  }
}
