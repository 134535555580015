import { of } from 'rxjs';
import { Setting, Service, Customer } from './../../@resources/graphql/types/types';
export class Global {
    authenticated:boolean;
    lang:string='';
    setting:Setting;
    services:Service[];
    customer:any

    listCustomer() {
        return of(this.customer);
    }
}
