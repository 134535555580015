<router-outlet></router-outlet>
<div class="d-flex justify-content-between">
  <a href="https://api.whatsapp.com/message/XH7HVQCWOWPOL1" class="float" target="_blank" id="phone_icon">
    <i class="fa fa-whatsapp my-float"></i>
  </a>
  <a class="floatLeft" target="_blank" href="tel:920020140" id="whatsapp_icon">
    <img src="assets/images/telephone.svg" class="w-75" alt="whatsApp icon" />
  </a>

  <!-- <svg-icon
  class="floatLeft"
  src="assets/images/icon-whatsapp-white.svg"
></svg-icon> -->
</div>
<!-- <div class="arrow"></div> -->
